import React, { useEffect, useState } from 'react';

import { getMedia } from '../services/fileUploadService';

let mediaContext;
const defaultStates = {};
const { Provider } = (mediaContext = React.createContext(defaultStates));

export const useMediaContext = () => React.useContext(mediaContext);

export default function MediaProvider({ interviewId, children }) {
  const [medias, setMedias] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const setMedia = async () => {
      if (!interviewId) return;
      const { data } = await getMedia(interviewId);
      setMedias(data.media);
    };
    if (interviewId) setMedia();
  }, [interviewId]);

  return (
    <Provider
      value={{
        interviewId,
        isSidebarOpen,
        setIsSidebarOpen,
        medias,
        setMedias,
      }}>
      {children}
    </Provider>
  );
}
