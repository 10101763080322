import http from './httpService';

export async function verifyAccessToken({ id, ...payload }) {
  const { data } = await http.post(`meetings/${id}`, payload);
  return data;
}

export default {
  verifyAccessToken,
};
