import './Admin.css';

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ProtectedRoute from '../components/ProtectedRoute';
import ScheduleDetail from '../components/report/schedules/ScheduleDetail';
import TopNavbar from '../components/TopNavbar';
import useAuth from '../hooks/useAuth';
import RouteProvider, { useRouteContext } from '../hooks/useRouter';
import NotFound from '../pages/404';

function Admin() {
  useAuth();
  const { prefix, routes } = useRouteContext();
  return (
    <RouteProvider>
      <TopNavbar />
      <main id="admin" role="main" className="container-fluid mt-5">
        <Switch>
          <Redirect from={prefix} to={`${prefix}/reports`} exact />
          {routes.map((route, key) => {
            return (
              <ProtectedRoute
                key={key}
                path={`${prefix}/${route.url}`}
                component={route.component}
              />
            );
          })}
          <ProtectedRoute
            path={`${prefix}/schedules/:id`}
            component={ScheduleDetail}
          />
          <Route path="/*" component={NotFound} />
        </Switch>
      </main>
    </RouteProvider>
  );
}

export default Admin;
