import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function ButtonCopyUrl() {
  const { t } = useTranslation('common');

  const onCopyUrl = async () => {
    const cpLink = document.getElementById('url');
    cpLink.select();
    document.execCommand('copy');
    if (!cpLink) {
      toast.error('URLコピー失敗', { autoClose: 2000 });
    }
    toast.success('URLコピーしました ', { autoClose: 2000 });
  };

  return (
    <div className="tooltip" onClick={onCopyUrl} role="button" tabIndex={0}>
      <FontAwesomeIcon icon="copy" size="lg" className="icon-copy" />
      <span className="tooltiptext">{t('copy-url')}</span>
    </div>
  );
}

export default memo(ButtonCopyUrl);
