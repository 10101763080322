import React, { memo } from 'react';

import Conversation from './Conversation';
import MessageBox from './MessageBox';

function LiveMessaging() {
  return (
    <div id="chat-form" className="position-absolute w-100 d-flex flex-column">
      <Conversation />
      <MessageBox />
    </div>
  );
}

export default memo(LiveMessaging);
