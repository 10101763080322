import React, { memo, useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { useChannelContext } from '../../../hooks/useChannel';

function ButtonRecord({ recordDefault }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const { mediasoup } = useChannelContext();

  const startRecording = useCallback(async () => {
    if (!isRecording && mediasoup?.manager) {
      setIsLoading(true);
      let tries = 0;
      let response = null;
      try {
        while (tries++ < 15) {
          console.log(`Waiting for recorder. Tries: ${tries}`);
          response = await mediasoup.manager.startRecord();
          if (response) break;
        }
        if (!response) throw new Error();
        setIsRecording(true);
      } catch (error) {
        console.error('Recording failed');
      } finally {
        setIsLoading(false);
      }
    }
  }, [isRecording, mediasoup.manager]);

  const stopRecording = useCallback(async () => {
    if (isRecording && mediasoup?.manager) {
      setIsLoading(true);
      await mediasoup.manager.stopRecord();
      setIsLoading(false);
      setIsRecording(false);
    }
  }, [isRecording, mediasoup.manager]);

  useEffect(() => {
    recordDefault && setTimeout(startRecording, 1000);
  }, [recordDefault]);

  if (isLoading) {
    return (
      <div className="tooltip">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </div>
    );
  }

  return (
    <div className="tooltip">
      <div
        style={{ display: isRecording ? 'block' : 'none' }}
        onClick={stopRecording}
        role="button"
        tabIndex={0}>
        <div className="border-record">
          <div className="item-record-active" />
        </div>
        <span className="tooltiptext">録画停止</span>
      </div>
      <div
        style={{ display: isRecording ? 'none' : 'block' }}
        onClick={startRecording}
        role="button"
        tabIndex={0}>
        <div className="border-record">
          <div className="item-record" />
        </div>
        <span className="tooltiptext"> 録画開始</span>
      </div>
    </div>
  );
}

export default memo(ButtonRecord);
