import config from './configService';

const isClientSetting = () => {
  const pathname = window.location.pathname;
  return ['meetings', 'interviews'].some(
    (item) => pathname.indexOf(item) !== -1
  );
};

const setClientMeta = (clientId) => {
  if (!clientId) return console.warn('No client_id set!');
  const client = config.get(`client.${clientId}`);
  const favicon = document.getElementById('favicon');
  favicon.href = client.favicon;
  document.title = client.title;
};

export default {
  isClientSetting,
  setClientMeta,
};
