import axios from 'axios';
import { toast } from 'react-toastify';

import config from './configService';

axios.defaults.baseURL =
  config.get('app.environment') === 'development'
    ? '/api'
    : config.get('api.url');
axios.defaults.headers.common['x-api-key'] = config.get('api.key');
axios.defaults.timeout = 1000 * config.get('axios.timeout');

axios.interceptors.response.use(null, (error) => {
  if (error.response?.status === 404) {
    window.location.href = '/404';
  }
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.error(error);
    toast.error('An unexpected error occurred.');
    window.location.href = '/error';
  }

  return Promise.reject(error);
});

export function setAccessToken(token) {
  axios.defaults.headers.common['Authorization'] = token;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setAccessToken,
};
