import React from 'react';

import { useChannelContext } from '../../hooks/useChannel';
import config from '../../services/configService';
import LiveMessaging from '../liveMessaging';
import LocalVideoPlayer from '../videoScreen/localVideoPlayer';
import ButtonCopyUrl from './buttons/ButtonCopyUrl';
import ButtonLeaveChannel from './buttons/ButtonLeaveChannel';
import ButtonRecord from './buttons/ButtonRecordScreen';
import ButtonShareScreen from './buttons/ButtonShareScreen';

const isRecordingDisabled = config.get('media.isRecordingDisabled');

function SidebarInterview() {
  const { role, mediasoup } = useChannelContext();

  return (
    <div className="position-relative w-100 h-100">
      <div className="live-board-controller">
        <input
          id="url"
          style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
          value={window.location.href}
          readOnly
        />
        <ButtonCopyUrl />
        {!isRecordingDisabled &&
          role === 'doctor' &&
          mediasoup.hasActiveRemotePeers && <ButtonRecord />}
        {mediasoup.hasActiveRemotePeers && (
          <>
            <ButtonShareScreen />
          </>
        )}
        <ButtonLeaveChannel />
      </div>
      <div className="video-local-stream bg-dark">
        <LocalVideoPlayer />
      </div>
      <LiveMessaging />
    </div>
  );
}

export default SidebarInterview;
