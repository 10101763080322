import React from 'react';

import routes from '../routes/admin';
import config from '../services/configService';

let RouteContext;
const defaultStates = {
  prefix: config.get('web.admin.prefix'),
  routes,
};
const { Provider } = (RouteContext = React.createContext(defaultStates));

export const useRouteContext = () => React.useContext(RouteContext);
export default function RouteProvider({ children }) {
  return <Provider value={defaultStates}>{children}</Provider>;
}
