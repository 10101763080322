import '../components/channel/index.css';

import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import Loader from '../components/common/Loader';
import SidebarMeeting from '../components/sidebar/SidebarMeeting';
import VideoScreen from '../components/videoScreen';
import ChannelProvider from '../hooks/useChannel';
import LiveChatProvider from '../hooks/useLiveChat';
import useQuery from '../hooks/useQuery';
import useUniqueId from '../hooks/useUniqueId';
import UserMediaProvider from '../hooks/useUserMedia';
import appService from '../services/appService';
import meetingService from '../services/meetingService';
const CHANNEL_PREFIX = 'meeting';
const ROLE = 'staff';

function MeetingPage() {
  const query = useQuery();
  const { meetingId } = useParams();
  const channelName = CHANNEL_PREFIX + meetingId;
  const uid = useUniqueId(channelName, ROLE);
  const userId = uid.getId();

  const [meeting, setMeeting] = useState();

  useEffect(() => {
    const setClientMeta = async () => {
      if (!meetingId) return;
      const data = await meetingService.verifyAccessToken({
        id: meetingId,
        token: query.get('token'),
      });
      const { client_id } = data;
      appService.setClientMeta(client_id);
      setMeeting(data);
    };
    setClientMeta();
  }, []);

  if (!meeting) {
    return <Loader />;
  }

  const channelProps = {
    channelName,
    userId,
    role: ROLE,
    maxParticipants: meeting.max_participants,
    conversation: '',
  };

  return (
    <UserMediaProvider>
      <ChannelProvider {...channelProps}>
        <div className="video-board h-100">
          <VideoScreen />
          <LiveChatProvider>
            <div id="local-screen">
              <SidebarMeeting meeting={meeting} />
            </div>
          </LiveChatProvider>
        </div>
      </ChannelProvider>
    </UserMediaProvider>
  );
}

export default withRouter(MeetingPage);
