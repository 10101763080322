export class Queue {
  tasks = [];
  executing = false;

  next = async () => {
    const task = this.tasks.shift();
    if (!task) {
      this.executing = false;
      return;
    }
    const { resolve, fn } = task;
    try {
      resolve(await fn());
    } catch (e) {
      console.error('Error in Queue');
      resolve(null);
    }
    if (!this.tasks.length) this.executing = false;
  };

  add = (fn, resolve) => {
    this.tasks.push({ fn, resolve });
    if (!this.executing) this.startExecution();
  };

  startExecution = () => {
    this.executing = true;
    const that = this;
    (async function () {
      while (that.executing) {
        await that.next();
      }
    })();
  };

  clear = () => {
    this.tasks.forEach((task) => task.resolve(null));
    this.tasks = [];
    this.executing = false;
  };
}
