import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

import config from '../../services/configService';
import useMediasoup from '../useMediasoup';

const { uri } = config.get('socket');

let dummyChannelContext;
const defaultStates = {
  channelName: '',
  userId: '',
};
const { Provider } = (dummyChannelContext = React.createContext(defaultStates));

export const useDummyChannelContext = () =>
  React.useContext(dummyChannelContext);

export default function DummyChannelProvider(props) {
  const [socket, setSocket] = useState(null);
  const { userId, channelName } = props;
  const mediasoup = useMediasoup(userId, channelName, socket, false);

  useEffect(() => {
    const connectSocket = () => {
      return io(uri, {
        query: {
          uid: userId,
          room: channelName,
          conversation: null,
        },
      });
    };
    const socket = connectSocket();
    setSocket(socket);

    return () => {
      if (socket) socket.disconnect();
      setSocket(null);
    };
  }, []);

  return (
    <Provider value={{ userId, channelName, socket, mediasoup }}>
      {props.children}
    </Provider>
  );
}
