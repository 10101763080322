import './index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo, useState } from 'react';

import { useChannelContext } from '../../hooks/useChannel';
import { useUserMediaContext } from '../../hooks/useUserMedia';
import VideoPlayer from './VideoPlayer';

const ButtonMic = ({ isMuted, onToggle }) => {
  return (
    <div onClick={onToggle}>
      <div className="icon-on" style={{ display: !isMuted ? 'block' : 'none' }}>
        <FontAwesomeIcon icon="microphone" />
      </div>
      <div className="icon-on" style={{ display: isMuted ? 'block' : 'none' }}>
        <FontAwesomeIcon icon="microphone-slash" />
      </div>
    </div>
  );
};

const ButtonCamera = ({ webcamActive, onToggle }) => {
  return (
    <div>
      <div
        className="icon-on"
        style={{ display: webcamActive ? 'block' : 'none' }}>
        <FontAwesomeIcon icon="video" onClick={onToggle} />
      </div>
      <div
        className="icon-on"
        style={{ display: !webcamActive ? 'block' : 'none' }}>
        <FontAwesomeIcon icon="video-slash" onClick={onToggle} />
      </div>
    </div>
  );
};

const LocalVideoPlayer = () => {
  const { mediasoup } = useChannelContext();
  const userMedia = useUserMediaContext();
  const [audioMuted, setAudioMuted] = useState(false);

  const toggleMicrophone = () => {
    if (!mediasoup.initialized) return;
    if (audioMuted) {
      mediasoup.manager.createProducer(userMedia.audioTrack);
      setAudioMuted(false);
    } else {
      mediasoup.manager.closeProducer('MIC');
      setAudioMuted(true);
    }
  };

  const toggleWebcam = () => {
    if (!mediasoup.initialized) return;
    if (userMedia.screenTrack) {
      userMedia.setWebcamActive(!userMedia.webcamActive);
    } else if (!userMedia.webcamActive) {
      mediasoup.manager.createProducer(userMedia.webcamTrack);
      userMedia.setWebcamActive(true);
    } else {
      mediasoup.manager.closeProducer('VIDEO');
      userMedia.setWebcamActive(false);
    }
  };

  return (
    <div className="local-stream-player">
      <div className="live-local-stream">
        <div className={userMedia.webcamActive ? 'live-user' : 'placeholder'}>
          <VideoPlayer
            tracks={userMedia.webcamTrack ? [userMedia.webcamTrack] : []}
          />
        </div>
        <div className={userMedia.webcamActive ? 'placeholder' : 'live-user'}>
          <div className="default-user" />
        </div>
        <div className="local-video-controls">
          <div className="item">
            <div className="item-icon">
              <ButtonMic onToggle={toggleMicrophone} isMuted={audioMuted} />
              <ButtonCamera
                onToggle={toggleWebcam}
                webcamActive={userMedia.webcamActive}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LocalVideoPlayer);
