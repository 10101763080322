import { useEffect, useState } from 'react';

import { SocketEvents } from '../helpers/constants';
import { MsManager } from '../mediasoup-plugin';
import { useUserMediaContext } from './useUserMedia';

export default function useMediasoup(uid, room, socket, canProduce = true) {
  const userMedia = useUserMediaContext();
  const [manager, setManager] = useState(null);
  const [hasActiveRemotePeers, setHasActiveRemotePeers] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const setMediasoupListeners = (msManager) => {
    socket.on(SocketEvents.NEW_CONSUMER, (payload) => {
      msManager.onNewProducer(payload);
    });

    socket.on(SocketEvents.PRODUCER_CLOSED, (payload) => {
      msManager.onClosedProducer(payload);
    });

    socket.on(SocketEvents.PEER_JOINED, (payload) => {
      msManager.onPeerJoin(payload);
    });

    socket.on(SocketEvents.PEER_LEFT, (payload) => {
      msManager.onPeerLeave(payload);
    });
  };

  useEffect(() => {
    const initMediasoup = async () => {
      while (!socket.connected) {
        console.warn(
          'Waiting for socket connection to initialize Mediasoup...'
        );
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      const msManager = new MsManager(
        uid,
        room,
        socket,
        setHasActiveRemotePeers
      );
      setManager(msManager);
      setMediasoupListeners(msManager);
      await msManager.createTransports(canProduce);
      await msManager.createRoomConsumers();
      if (canProduce) {
        const { webcamTrack, audioTrack } = userMedia;
        webcamTrack && msManager.createProducer(webcamTrack);
        audioTrack && msManager.createProducer(audioTrack);
      }
      setInitialized(true);
    };
    if ((socket && userMedia?.initialized) || (socket && !canProduce))
      initMediasoup();

    return () => {
      if (manager) {
        manager.closeTransports();
        setManager(null);
      }
    };
  }, [socket, userMedia?.initialized, canProduce]);

  return { manager, hasActiveRemotePeers, initialized };
}
