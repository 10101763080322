import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

import config from '../services/configService';
import NavbarLinks from './NavbarLinks';

const TopNavbar = () => {
  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Container fluid>
        <Link to="/" className="navbar-brand">
          {config.get('app.name')}
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <NavbarLinks />
          </Nav>
          <Nav className="ml-auto">
            <div className="nav-item">
              <NavLink to="/logout" className="nav-link">
                ログアウト
              </NavLink>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
