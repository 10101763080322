import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import config from '../services/configService';

const { videoOptions, screenCaptureOptions } = config.get('media');

let userMediaContext;
const defaultStates = {};
const { Provider } = (userMediaContext = React.createContext(defaultStates));

export const useUserMediaContext = () => React.useContext(userMediaContext);

export default function UserMediaProvider(props) {
  const [audioTrack, setAudioTrack] = useState(null);
  const [webcamTrack, setWebcamTrack] = useState(null);
  const [screenTrack, setScreenTrack] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [webcamActive, setWebcamActive] = useState(true);

  const webcamRef = useRef(null);
  const screenRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    webcamRef.current = webcamTrack;
    screenRef.current = screenTrack;
    audioRef.current = audioTrack;
  }, [webcamTrack, screenTrack, audioTrack]);

  useEffect(() => {
    const getUserDevices = async () => {
      let tracks;
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: videoOptions,
          audio: true,
        });
        tracks = stream.getTracks();
      } catch (error) {
        switch (error.name) {
          case 'NotFoundError':
            try {
              const videoStream = await navigator.mediaDevices.getUserMedia({
                video: videoOptions,
              });
              tracks = videoStream.getTracks();
            } catch {
              //
            }
            try {
              const audioStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
              });
              tracks = audioStream.getTracks();
            } catch {
              //
            }
            break;
          case 'NotAllowedError':
            console.warn('Not allowed');
            break;
          default:
            console.warn(error);
        }
      }
      tracks.map((track) => {
        track.kind === 'video' ? setWebcamTrack(track) : setAudioTrack(track);
      });
      setInitialized(true);
    };

    if (!initialized) getUserDevices();

    return () => {
      webcamRef.current && webcamRef.current.stop();
      screenRef.current && screenRef.current.stop();
      audioRef.current && audioRef.current.stop();
    };
  }, []);

  const getUserDisplay = async () => {
    try {
      if (!navigator.mediaDevices.getDisplayMedia) {
        toast.error('画面共有はデスクトップ版のみ可能です。');
        return;
      }
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: screenCaptureOptions,
      });
      const track = stream.getVideoTracks()[0];
      setScreenTrack(track);
    } catch (e) {
      console.warn(e);
      return null;
    }
  };

  const stopScreenShare = () => {
    if (screenTrack) {
      screenTrack.stop();
      setScreenTrack(null);
    }
  };

  const getWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoOptions,
      });
      setWebcamTrack(stream.getVideoTracks()[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getMicrophone = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setAudioTrack(stream.getAudioTracks()[0]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Provider
      value={{
        getUserDisplay,
        stopScreenShare,
        getWebcam,
        getMicrophone,
        webcamTrack,
        screenTrack,
        audioTrack,
        initialized,
        webcamActive,
        setWebcamActive,
      }}>
      {props.children}
    </Provider>
  );
}
