import React from 'react';

function Error(props) {
  let message = 'Error! Service not available.';
  if (props.location.state) {
    message = props.location.state.message;
  }

  return (
    <div className="container d-flex justify-content-center h-100">
      <div className="flex-column justify-content-center align-self-center">
        <h2 className="font-weight-light">Oops! Something went wrong</h2>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default Error;
