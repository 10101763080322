import './index.css';

import React, { memo, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useChannelContext } from '../../hooks/useChannel';
import { useLiveChatContext } from '../../hooks/useLiveChat';

const LocalMessage = memo((props) => {
  return (
    <div className="message">
      <div className="message-local-username">
        <div className="border-local-username">
          <p className="local-name text-uppercase">{props.userName[0]}</p>
        </div>
      </div>
      <div className="border-local-message">
        <span className="local-message">{ReactHtmlParser(props.message)}</span>
      </div>
    </div>
  );
});
LocalMessage.displayName = 'LocalMessage';

const RemoteMessage = memo((props) => {
  return (
    <div className="message">
      <div className="message-remote-username">
        <div className="border-remote-username">
          <p className="remote-name text-uppercase">{props.userName[0]}</p>
        </div>
      </div>
      <div className="border-remote-message">
        <span className="remote-message">{ReactHtmlParser(props.message)}</span>
      </div>
    </div>
  );
});
RemoteMessage.displayName = 'RemoteMessage';

function Conversation() {
  const messagesEndRef = useRef(null);
  const { userId } = useChannelContext();
  const { messages } = useLiveChatContext();

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <div id="conversations">
      <div id="messages">
        {messages.map((message, key) => {
          return userId === message.sender_id ? (
            <LocalMessage
              key={`local_${key}`}
              message={message.content}
              userName={message.sender_id}
            />
          ) : (
            <RemoteMessage
              key={`remote_${key}`}
              message={message.content}
              userName={message.sender_id}
            />
          );
        })}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
}

export default Conversation;
