import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Table } from 'react-bootstrap';

function MediaRow({ media, handleDelete, checkStatus }) {
  const onDelete = (evt) => {
    evt.preventDefault();
    handleDelete(media.id);
  };

  return (
    <tr key={media.id}>
      <td>
        <FontAwesomeIcon icon="file-alt" size="3x" />
      </td>
      <td className="w-100">
        <a
          href={media.src}
          target="_blank"
          rel="noopener noreferrer"
          className="text-break">
          {media.src}
        </a>
      </td>
      <td>
        {checkStatus !== 'icon-delete' ? (
          ''
        ) : (
          <button onClick={onDelete} className="btn">
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </td>
    </tr>
  );
}

export default function MediaList({ medias, handleDelete, checkStatus }) {
  return (
    <Card className="mb-2">
      <Card.Body>
        <Card.Text className="font-weight-bold">メディア</Card.Text>
        <Table className="responsive" style={{ minHeight: '250px' }}>
          <tbody className="overflow-auto list-media">
            {medias &&
              medias.map((media) => {
                return (
                  <MediaRow
                    key={media.id}
                    media={media}
                    handleDelete={handleDelete}
                    checkStatus={checkStatus}
                  />
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
