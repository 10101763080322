import React from 'react';

import config from '../services/configService';
export default function HomePage() {
  return (
    <div className="container d-flex justify-content-center h-100">
      <div className="flex-column justify-content-center align-self-center">
        <h5>
          <em>Welcome to</em>
        </h5>
        <div className="ml-5">
          <h1 className="font-weight-light">{config.get('app.name')}</h1>
          <h4>{config.get('app.description')}</h4>
        </div>
      </div>
    </div>
  );
}
