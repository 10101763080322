import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useChannelContext } from '../../hooks/useChannel';
import Loader from '../common/Loader';
import VideoPlayer from './VideoPlayer';

const getRowsAndColumns = (
  numberOfStreams,
  containerWidth,
  containerHeight
) => {
  const aspectRatio = 16 / 9;
  const containerAspectRation = containerWidth / containerHeight;
  const isLandscape = containerAspectRation <= aspectRatio;
  let columns = 1;
  if (numberOfStreams === 1) {
    return {
      columns,
      itemWidth: isLandscape ? containerWidth : containerHeight * aspectRatio,
      itemHeight: isLandscape ? containerWidth / aspectRatio : containerHeight,
    };
  }
  let itemHeight = 0,
    itemWidth = 0;
  if (numberOfStreams < 5) {
    itemHeight = containerWidth / aspectRatio;
    columns = containerHeight >= numberOfStreams * itemHeight ? 1 : 2;
  } else if (numberOfStreams < 7) {
    const itemHeight = containerWidth / 2 / aspectRatio;
    const tempRows = Math.ceil(numberOfStreams / 2);
    columns = containerHeight >= tempRows * itemHeight ? 2 : 3;
  } else columns = 3;
  while (true) {
    itemWidth = containerWidth / columns;
    itemHeight = itemWidth / aspectRatio;
    if (itemWidth >= 160 || columns === 1) break;
    columns--;
  }
  return {
    columns,
    itemWidth,
    itemHeight,
  };
};

function RemoteStream({ peer, numPeers, setTracksCallback }) {
  const [container, setContainer] = useState({
    height: 0,
    weight: 0,
  });

  const detectSize = () => {
    let box = document.querySelector('#remote-screen');
    setContainer({
      width: box.offsetWidth,
      height: box.offsetHeight,
    });
  };

  useEffect(() => {
    detectSize();
    window.addEventListener('resize', detectSize);
    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, []);

  let { columns, itemWidth, itemHeight } = getRowsAndColumns(
    numPeers,
    container.width,
    container.height
  );
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    setTracksCallback(peer.id, setTracks);
  }, []);

  return (
    <Col sm={12 / columns}>
      <div
        style={{
          height: `${itemHeight}px`,
          width: `${itemWidth}px`,
        }}>
        <VideoPlayer tracks={tracks} />
      </div>
    </Col>
  );
}

function VideoScreen() {
  const [peers, setPeers] = useState([]);
  const { mediasoup } = useChannelContext();

  useEffect(() => {
    const setPeerUpdateFunction = () => {
      mediasoup.manager.setPeerUpdateFunction(setPeers);
    };

    if (mediasoup.manager) setPeerUpdateFunction();
  }, [mediasoup.manager]);

  if (!mediasoup.initialized) {
    return <Loader />;
  }

  const setTracksCallback = (peerId, cb) => {
    mediasoup.manager.setTracksCallback(peerId, cb);
  };

  return (
    <div
      id="remote-screen"
      className="bg-dark d-flex"
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
      }}>
      <div className="align-self-center w-100 mh-100">
        <div className="align-self-center w-100">
          {(mediasoup.hasActiveRemotePeers && (
            <Row noGutters>
              {peers.map((peer) => (
                <RemoteStream
                  key={peer.id}
                  peer={peer}
                  numPeers={peers.length}
                  setTracksCallback={setTracksCallback}
                />
              ))}
            </Row>
          )) || (
            <div className="w-100 d-flex align-items-center">
              <h1 className="w-100 text-center" style={{ color: 'white' }}>
                他の人を待っています
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoScreen;
