import React, { useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';

export default function Filter({ handleFilter }) {
  const [requestId, setRequestId] = useState('');

  const onClick = (evt) => {
    evt.preventDefault();
    handleFilter(requestId);
  };

  return (
    <Form className="mb-3">
      <Form.Row>
        <Col>
          <Form.Control
            placeholder="リクエストID"
            value={requestId}
            onChange={(evt) => setRequestId(evt.target.value)}
          />
        </Col>
        <Col>
          <Col xs="auto">
            <Button type="submit" onClick={onClick}>
              フィルタ
            </Button>
          </Col>
        </Col>
      </Form.Row>
    </Form>
  );
}
