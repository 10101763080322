import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import authService from '../services/authService';

function ProtectedRoute({ component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authService.isLoggedIn()) {
          return <Redirect to="/login" />;
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
}

export default ProtectedRoute;
