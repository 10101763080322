import { format } from 'date-fns';
import React, { useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  createMedia,
  deleteMedia,
  uploadFile,
} from '../../../services/fileUploadService';
import ChatList from '../../interview/ChatList';
import MediaList from '../../interview/MediaList';

export default function InterviewItem({ interview }) {
  const [medias, setMedias] = useState(interview.media || []);
  const [isLoading, setLoading] = useState(false);

  const setTime = () => {
    return new Promise((resolve) => setTimeout(resolve, 500));
  };

  const onFileUpload = async (event) => {
    event.preventDefault();
    const input = event.target;
    const selectedFile = input.files[0];
    try {
      if (!selectedFile) return null;
      const { url } = await uploadFile(selectedFile);
      if (!isLoading) {
        setLoading(true);
        const media = await createMedia({
          src: url,
          interview_id: interview.id,
          type: 'DOCUMENT',
        });
        setTime().then(() => {
          setLoading(false);
        });
        setMedias([...medias, media]);
        input.value = '';
        toast.success('ファイルアプロード成功', { autoClose: 2000 });
      }
    } catch (e) {
      toast.error('ファイルアプロード失敗', { autoClose: 2000 });
    }
  };

  const handleDelete = async (id) => {
    setMedias(medias.filter((media) => media.id !== id));
    await deleteMedia(id);
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <Link to={`/admin/interviews/${interview.id}`}>
          Interview Id: {interview.id}
        </Link>
        <div className="btn-file tooltip float-right">
          <input type="file" onChange={onFileUpload} />
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <Button className="float-right" variant="primary" size="sm">
              アップロード
            </Button>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          開始日時:
          {format(new Date(interview.begin_at), 'yyyy年MM月dd日 hh:mm')}
        </Card.Text>
        <Card.Text>
          終了日時:
          {format(new Date(interview.finish_at), 'yyyy年MM月dd日 hh:mm')}
        </Card.Text>
        <Row>
          <Col sm={6}>
            <MediaList
              medias={medias || []}
              handleDelete={handleDelete}
              checkStatus={interview.id && 'icon-delete'}
            />
          </Col>
          <Col sm={6}>
            <ChatList messages={interview?.conversation?.messages || []} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
