import './index.css';

import React from 'react';
import { Card } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

export default function ChatList({ messages }) {
  return (
    <Card className="mb-2">
      <Card.Body>
        <Card.Text className="font-weight-bold">チャット履歴</Card.Text>
        <div className="overflow-auto" style={{ height: '265px' }}>
          {messages.map((message) => {
            return (
              <div key={Math.random()} className="mb-3">
                <p className="mb-0">
                  <em>{message.sender_id[0].toUpperCase()}: </em>
                  {ReactHtmlParser(message.content)}
                </p>
              </div>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
}
