import './User.css';

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LeftChannel from '../components/channel/Left';
import Interview from '../components/interview';
import NotFound from '../pages/404';
import DummyPage from '../pages/dummy';
import Error from '../pages/error';
import HomePage from '../pages/home';
import InterviewPage from '../pages/interview';
import MeetingPage from '../pages/meetings';

function UserSite() {
  return (
    <main id="user-site" role="main">
      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/channel/left" component={LeftChannel} exact />
        <Route path="/channel/:interviewId/:role" component={InterviewPage} />
        <Route path="/meetings/:meetingId" component={MeetingPage} />
        <Route path="/interviews/:id" component={Interview} />
        <Route path="/dummy" component={DummyPage} />
        <Route path="/error" component={Error} />
        <Route path="/*" component={NotFound} />
      </Switch>
    </main>
  );
}

export default UserSite;
