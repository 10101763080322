import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';

import { useLiveChatContext } from '../../hooks/useLiveChat';

function MessageBox() {
  const { sendMessage } = useLiveChatContext();
  const [value, setValue] = useState('');
  const [rows, setRows] = useState(1);
  const [visibilityIcon, setVisibilityIcon] = useState(false);

  const minRows = 1;
  const maxRows = 6;
  const innerRef = useRef();

  const onChange = (evt) => {
    const input = evt.target;
    setVisibilityIcon(input.value.trim().length > 0);

    const textareaLineHeight = 24;
    const previousRows = input.rows;
    input.rows = minRows;
    const currentRows = ~~(input.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      input.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      input.rows = maxRows;
      input.scrollTop = input.scrollHeight;
    }
    setRows(currentRows < maxRows ? currentRows : maxRows);
    setValue(input.value);
  };

  const onKeyPress = (evt) => {
    if (evt.key === 'Enter' && evt.shiftKey === false) {
      evt.preventDefault();
      handleSubmit(evt);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!value.trim()) return;

    setRows(minRows);
    setVisibilityIcon(false);
    setValue('');
    await sendMessage(value);
  };

  return (
    <Form onSubmit={handleSubmit} onKeyPress={onKeyPress} id="form">
      <InputGroup>
        <FormControl
          as="textarea"
          placeholder="メッセージを入力する"
          onChange={onChange}
          rows={rows}
          value={value}
          id="form-message"
          ref={innerRef}
        />
        <InputGroup.Prepend>
          <Button type="submit" id="btn-message">
            <FontAwesomeIcon
              icon="paper-plane"
              className="icon-item-plane"
              style={{
                display: visibilityIcon ? 'block' : 'none',
              }}
            />
          </Button>
        </InputGroup.Prepend>
      </InputGroup>
    </Form>
  );
}

export default MessageBox;
