import http from './httpService';

export async function media(interviewId, src, type) {
  const { data } = await http.post('/interviews-media', {
    src,
    type,
    interview_id: interviewId,
  });
  return data;
}

export async function getInterviews(options) {
  const { data } = await http.get('/interviews', options);
  return data;
}

export async function getById(id) {
  const { data } = await http.get(`/interviews/${id}`);
  return data;
}

export async function verifyAccessToken({ id, ...payload }) {
  const { data } = await http.post(`interviews/${id}`, payload);
  return data;
}

export async function notify(id, requestId, url) {
  await http.post('/callback/notify-record', {
    request_id: requestId,
    interview_id: id,
    report_url: url,
  });
}

export default {
  media,
  getById,
  verifyAccessToken,
  getInterviews,
  notify,
};
