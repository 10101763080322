const soundFile = require('../assets/audiotest.mp3');

export class MixAudio {
  audioContext = null;
  audioDestination = null;
  remoteAudios = {};

  constructor() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();
    this.audioDestination = this.audioContext.createMediaStreamDestination();
    this.start();
  }

  async start() {
    const resp = await fetch(soundFile);
    const buf = await resp.arrayBuffer();
    const audioBuffer = await this.audioContext.decodeAudioData(buf);

    // Create the looping AudioBufferSource
    const source = this.audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.playbackRate.value = 0.1;
    source.loop = true;
    source.start(0);

    // Mute the sound and connect it to the destination
    const gainNode = this.audioContext.createGain();
    source.connect(gainNode);
    gainNode.gain.setValueAtTime(0, this.audioContext.currentTime);
    gainNode.connect(this.audioDestination);
  }

  getStream() {
    return this.audioDestination.stream;
  }

  addAudio(track) {
    const stream = new MediaStream([track]);

    // this audioElement is required to make audioDestion work
    const element = new Audio();
    element.muted = true;
    element.srcObject = stream;
    element.play();

    const source = this.audioContext.createMediaStreamSource(stream);
    source.connect(this.audioDestination);
    this.remoteAudios[track.id] = {
      source,
      element,
    };
  }

  removeAudio(track) {
    const { source } = this.remoteAudios[track.id];
    source.disconnect(this.mixAudio);
    delete this.remoteAudios[track.id];
  }
}
