import http from './httpService';

export async function getSchedules(options) {
  const { data } = await http.get('/admin/schedules', {
    params: options,
  });
  return data;
}

export async function getById(id) {
  const { data } = await http.get(`/admin/schedules/${id}`);
  return data;
}

export default {
  getById,
  getSchedules,
};
