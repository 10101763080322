export const changeAspectRatio = (sWidth, sHeight, aspectRatio) => {
  let x, y, width, height;

  const sApectRatio = sWidth / sHeight;
  if (sApectRatio > aspectRatio) {
    width = sHeight * aspectRatio;
    height = sHeight;
    x = (sWidth - width) / 2;
    y = 0;
  } else {
    width = sWidth;
    height = sWidth / aspectRatio;
    x = 0;
    y = (sHeight - height) / 2;
  }

  return {
    x,
    y,
    width,
    height,
  };
};
