import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useChannelContext } from '../../../hooks/useChannel';
import { useUserMediaContext } from '../../../hooks/useUserMedia';

function ButtonShareScreen({ inline = false }) {
  const { mediasoup } = useChannelContext();
  const userMedia = useUserMediaContext();
  const { t } = useTranslation('common');
  const webcamActiveRef = useRef(null);

  const handleShareScreen = () => {
    if (!mediasoup.initialized) return;
    if (!userMedia.screenTrack) userMedia.getUserDisplay();
  };

  const stopSharing = () => {
    if (webcamActiveRef.current)
      mediasoup.manager.replaceVideoTrack(userMedia.webcamTrack);
    else mediasoup.manager.closeProducer('VIDEO');
    userMedia.stopScreenShare();
  };

  useEffect(() => {
    if (userMedia.screenTrack) {
      userMedia.screenTrack.onended = stopSharing;
      mediasoup.manager.replaceVideoTrack(userMedia.screenTrack);
    }
  }, [userMedia.screenTrack]);

  useEffect(() => {
    webcamActiveRef.current = userMedia.webcamActive;
  }, [userMedia.webcamActive]);

  return (
    <div
      className="tooltip"
      onClick={handleShareScreen}
      role="button"
      tabIndex={0}>
      <FontAwesomeIcon icon="external-link-alt" size="lg" />
      {inline ? (
        <span className="dropdown-menu-text">{t('share-screen')}</span>
      ) : (
        <span className="tooltiptext">{t('share-screen')}</span>
      )}
    </div>
  );
}

export default memo(ButtonShareScreen);
