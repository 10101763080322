import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import auth from '../services/authService';
import http from '../services/httpService';

export const AuthContext = React.createContext({});
export const useAuthContext = () => React.useContext(AuthContext);

export function AuthProvider({ children }) {
  const [token, setToken] = useState();

  useEffect(() => {
    http.setAccessToken(token);
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        currentUser: auth.getCurrentUser(),
        isLoggedIn: auth.isLoggedIn(),
        setToken,
        token,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  const token = auth.getAccessToken();
  if (!token) {
    return <Redirect to="/login" />;
  }

  http.setAccessToken(token);
}
