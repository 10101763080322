import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

import config from '../services/configService';
import useMediasoup from './useMediasoup';

const { uri } = config.get('socket');

let channelContext;
const defaultStates = {
  channelName: '',
  userId: '',
};
const { Provider } = (channelContext = React.createContext(defaultStates));

export const useChannelContext = () => React.useContext(channelContext);

export default function ChannelProvider(props) {
  const [socket, setSocket] = useState(null);
  const mediasoup = useMediasoup(props.userId, props.channelName, socket);

  useEffect(() => {
    const connectSocket = () => {
      return io(uri, {
        query: {
          uid: props.userId,
          room: props.channelName,
          conversation: props.conversation?.id || null,
        },
      });
    };
    const socket = connectSocket();
    setSocket(socket);

    return () => {
      if (socket) socket.disconnect();
    };
  }, [props]);

  return (
    <Provider value={{ ...props, mediasoup, socket }}>
      {props.children}
    </Provider>
  );
}
