import { changeAspectRatio } from './changeAspectRatio';
import { calcMosiacLayout } from './mosiacLayout';

const ctxDrawVideo = (ctx, video, itemX, itemY, itemWidth, itemHeight) => {
  const { x, y, width, height } = changeAspectRatio(
    video.videoWidth,
    video.videoHeight,
    itemWidth / itemHeight
  );

  ctx.drawImage(
    video,
    x,
    y,
    width,
    height,
    itemX,
    itemY,
    itemWidth,
    itemHeight
  );
};

export class Drawer {
  containerWidth = null;
  containerHeight = null;

  canvas = null;
  videoContainer = null;

  ctx = null;
  layout = null;
  prevNumItems = null;

  keepAnimation = false;
  animationId = null;

  mixedStream = null;

  constructor(canvas, videoContainer, bgColor = '#000') {
    this.drawVideos = this.drawVideos.bind(this);

    this.containerWidth = canvas.width;
    this.containerHeight = canvas.height;
    this.videoContainer = videoContainer;
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.ctx.fillStyle = bgColor;
    this.ctxClear();
    this.mixedStream = this.canvas.captureStream(30);
    this.startDraw();
  }

  ctxClear() {
    this.ctx.fillRect(0, 0, this.containerWidth, this.containerHeight);
  }

  calcLayout(numItems) {
    if (this.prevNumItems === numItems) return this.layout;
    this.layout = calcMosiacLayout(
      this.containerWidth,
      this.containerHeight,
      numItems
    );
    this.prevNumItems = numItems;
    this.ctxClear();
    return this.layout;
  }

  startDraw() {
    this.animationId = window.requestAnimationFrame(this.drawVideos);
    this.keepAnimation = true;
  }

  stopDraw() {
    this.animationId = null;
    this.keepAnimation = false;
    this.mixedStream = null;
  }

  drawVideos() {
    const videos = Array.from(this.videoContainer.children);
    const numItems = videos.length;
    const { itemWidth, itemHeight, itemPositions } = this.calcLayout(numItems);

    videos.forEach((video, index) => {
      ctxDrawVideo(
        this.ctx,
        video,
        itemPositions[index].x,
        itemPositions[index].y,
        itemWidth,
        itemHeight
      );
    });

    if (this.keepAnimation) window.requestAnimationFrame(this.drawVideos);
  }

  getStream() {
    return this.mixedStream;
  }
}
