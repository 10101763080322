import * as _ from 'lodash';

import configs from '../config';

const get = (configKey) => {
  return _.get(configs, configKey);
};

export default {
  get,
};
