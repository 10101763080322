import React, { useCallback, useEffect, useState } from 'react';

import { SocketEvents } from '../helpers/constants';
import { useChannelContext } from './useChannel';

let liveChatContext;
const defaultStates = {};
const { Provider } = (liveChatContext = React.createContext(defaultStates));

export const useLiveChatContext = () => React.useContext(liveChatContext);

export default function LiveChatProvider(props) {
  const [messages, setMessages] = useState([]);
  const { socket, conversation } = useChannelContext();

  useEffect(() => {
    if (conversation) {
      setMessages(conversation.messages);
    }
  }, [conversation]);

  const onReceiveMessage = (message) => {
    const newMessage = {
      content: message.content,
      sender_id: message.uid,
    };
    setMessages((messages) => [...messages, newMessage]);
  };

  useEffect(() => {
    if (socket) {
      socket.on(SocketEvents.NEW_CHAT_MESSAGE, onReceiveMessage);
    }

    return () => {
      if (socket) {
        socket.off(SocketEvents.NEW_CHAT_MESSAGE, onReceiveMessage);
      }
    };
  }, [socket]);

  const sendMessage = useCallback(
    (content) => {
      if (!socket) return;
      if (!socket.connected) return console.error('Sent message failed...');
      socket.emit('SEND_NEW_MESSAGE', { content });
    },
    [socket]
  );

  return (
    <Provider value={{ messages, sendMessage }}>{props.children}</Provider>
  );
}
