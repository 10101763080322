import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './helpers/fontawesome';
import './App.scss';

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import Admin from './containers/AdminSiteContainer';
import User from './containers/UserSiteContainer';
import { AuthProvider } from './hooks/useAuth';
import appService from './services/appService';
import config from './services/configService';

function App() {
  const adminPrefix = config.get('web.admin.prefix');
  const userPrefix = config.get('web.user.prefix');

  useEffect(() => {
    const appDescription = config.get('app.description');
    const appName = appService.isClientSetting() ? '' : config.get('app.name');
    document.title = appName;
    document.querySelector('meta[name="description"]').content = appDescription;
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter basename="/live">
        <ToastContainer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path={adminPrefix} component={Admin} />
          <Route path={userPrefix} component={User} exact />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
