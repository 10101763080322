import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import scheduleService from '../../../services/scheduleService';
import InterviewItem from '../interviews/InterviewItem';

function ScheduleDetail() {
  const { id: scheduleId } = useParams();
  const [schedule, setSchedule] = useState();

  useEffect(() => {
    const getSchedule = async () => {
      setSchedule(await scheduleService.getById(scheduleId));
    };
    if (scheduleId) getSchedule();
  }, [scheduleId]);

  if (!schedule) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <h4 className="text-center">リクエストID: {schedule.id}</h4>
      {schedule &&
        schedule.interviews.map((interview) => (
          <InterviewItem key={interview.id} interview={interview} />
        ))}
    </div>
  );
}

export default ScheduleDetail;
