import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import { useChannelContext } from '../../../hooks/useChannel';

function ButtonLeaveChannel({ inline = false }) {
  const { leaveChannel } = useChannelContext();

  const onLeave = (evt) => {
    evt.preventDefault();
    try {
      leaveChannel && leaveChannel();
    } catch (err) {
      console.error(`Failed to leave, ${err}`, { variant: 'error' });
    }
  };

  return (
    <div className="tooltip">
      <div className="leave" onClick={onLeave}>
        <NavLink to="/channel/left" className="nav-link">
          <FontAwesomeIcon icon="sign-out-alt" size="lg" />
        </NavLink>
      </div>
      {inline ? (
        <span className="dropdown-menu-text">退出</span>
      ) : (
        <span className="tooltiptext">退出</span>
      )}
    </div>
  );
}

export default memo(ButtonLeaveChannel);
