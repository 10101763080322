import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';

import useQuery from '../../hooks/useQuery';
import interviewService from '../../services/interviewService';
import MediaList from './MediaList';

export default function Interview() {
  const query = useQuery();
  const { id } = useParams();
  const [interview, setInterview] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    const getInterview = async () => {
      try {
        const data = await interviewService.verifyAccessToken({
          id,
          role: 'doctor',
          token: query.get('token'),
        });
        setInterview(data);
      } catch (error) {
        setError(true);
      }
    };
    if (id) getInterview();
  }, [id]);

  if (error) {
    return <Redirect to="/404" />;
  }

  if (!interview) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-5">セッションレポート</h2>
      <Card className="mb-2">
        <Card.Body>
          <Card.Title>Interview ID: {interview.id}</Card.Title>
          <Card.Text>
            開始日時:
            {format(
              new Date(interview.actual_begin_at || interview.begin_at),
              'yyyy年MM月dd日 HH:mm'
            )}
          </Card.Text>
          <Card.Text>
            終了日時:
            {format(
              new Date(interview.actual_finish_at || interview.finish_at),
              'yyyy年MM月dd日 HH:mm'
            )}
          </Card.Text>
          <Row>
            <Col sm={12}>
              <MediaList medias={interview.media || []} />
            </Col>
            {/* <Col sm={6}>
              <ChatList messages={messages} />
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
