import React, { memo, useEffect, useRef } from 'react';

import { byId } from '../../helpers/byId';

const VideoPlayer = ({ tracks }) => {
  const videoElement = useRef(null);
  const tracksRef = useRef([]);

  useEffect(() => {
    const element = videoElement?.current;
    if (element) {
      const newStream = new MediaStream();
      element.srcObject = newStream;
    }

    return () => {
      element && (element.srcObject = null);
    };
  }, [videoElement]);

  useEffect(() => {
    const stream = videoElement?.current?.srcObject;

    const updateTracks = () => {
      const oldTracks = tracksRef.current;
      for (const track of oldTracks) {
        if (byId(tracks, track.id)) continue;
        stream.removeTrack(track);
      }

      for (const track of tracks) {
        if (byId(oldTracks, track.id)) continue;
        stream.addTrack(track);
      }

      tracksRef.current = stream.getTracks();
    };

    stream && updateTracks();
  }, [videoElement, tracks]);

  return (
    <video
      ref={videoElement}
      className="w-100 h-100"
      style={{ objectFit: 'contain' }}
      autoPlay
      playsInline
    />
  );
};

export default memo(VideoPlayer);
