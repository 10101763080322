import React from 'react';
import { useTranslation } from 'react-i18next';

function LeftChannel() {
  const { t } = useTranslation();
  return (
    <div className="container d-flex justify-content-center h-100">
      <div className="flex-column justify-content-center align-self-center">
        <h2 className="font-weight-light">{t('message:left-channel')}</h2>
        <p>{t('message:ended-session')}</p>
      </div>
    </div>
  );
}

export default LeftChannel;
