import React, { useEffect, useState } from 'react';

import Loader from '../../../components/common/Loader';
import useAuth from '../../../hooks/useAuth';
import scheduleService from '../../../services/scheduleService';
import FilterForm from './FilterForm';
import ScheduleList from './ScheduleList';

function Report() {
  useAuth();
  const [schedules, setSchedules] = useState([]);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const initialSchedules = async () => {
      const pagination = await scheduleService.getSchedules({ limit, page });
      setSchedules(pagination.data);
      setLoading(false);
    };

    initialSchedules();
  }, []);

  const handleFilter = async (requestId) => {
    setLoading(false);
    let options = {
      limit,
      page: 1,
    };
    if (requestId) {
      options.filter = [`id||$cont||${requestId}`];
    }

    const pagination = await scheduleService.getSchedules(options);
    setSchedules(pagination.data);
    setFilters(requestId ? options.filter : []);
    setPage(1);
    setTimeout(() => setLoading(false), 100);
  };

  const showMore = async () => {
    let options = {
      limit,
      page: page + 1,
    };
    if (filters) {
      options.filter = filters;
    }
    const pagination = await scheduleService.getSchedules(options);
    setSchedules([...schedules, ...pagination.data]);
    pagination.count > 0 && setPage(page + 1);
  };

  return (
    <div className="container mt-4">
      <h4>セッションレポート</h4>
      <FilterForm handleFilter={handleFilter} />
      {loading ? (
        <div style={{ height: '100px' }}>
          <Loader />
        </div>
      ) : (
        <ScheduleList schedules={schedules} />
      )}
      <button className="w-100 btn btn-light" onClick={showMore}>
        もっと見る
      </button>
    </div>
  );
}

export default Report;
