import React from 'react';
import { NavLink } from 'react-router-dom';

import { useRouteContext } from '../hooks/useRouter';

function NavbarLinks() {
  const { prefix, routes } = useRouteContext();

  return routes.map((item, key) => {
    return (
      <div className="nav-item" key={key}>
        <NavLink to={`${prefix}/${item.url}`} className="nav-link">
          {item.label}
        </NavLink>
      </div>
    );
  });
}

export default NavbarLinks;
