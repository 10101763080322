import http from './httpService';

const tokenKey = 'app_access_token';
const userKey = 'app_user';

export async function login(email, password) {
  const { data } = await http.post('/auth/login', { email, password });
  window.localStorage.setItem(tokenKey, JSON.stringify(data.token));
  window.localStorage.setItem(userKey, JSON.stringify(data.user));
  return data;
}

export async function logout() {
  window.localStorage.removeItem(tokenKey);
  window.localStorage.removeItem(userKey);
  await http.post('/auth/logout');
}

export function isLoggedIn() {
  const token = getToken();
  if (!token) return false;
  return true;
}

export function getCurrentUser() {
  return JSON.parse(window.localStorage.getItem(userKey));
}

function getToken() {
  return JSON.parse(window.localStorage.getItem(tokenKey));
}

export function getAccessToken() {
  const token = getToken();
  return token ? `Bearer ${token.accessToken}` : null;
}

export default {
  getAccessToken,
  getCurrentUser,
  isLoggedIn,
  login,
  logout,
};
