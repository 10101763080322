import { Device } from 'mediasoup-client';

import { byId } from '../helpers/byId';
import { Queue } from '../helpers/queue';
import configService from '../services/configService';
import signaling from './signaling';

const { encodingsHQ, encodingsMQ, turnIP, stunIP, turnUserName, turnPassword } =
  configService.get('media');

const iceServers = turnIP
  ? [
      { urls: stunIP },
      { urls: turnIP, username: turnUserName, credential: turnPassword },
    ]
  : undefined;

export class MsManager {
  sendTransport = null;
  recvTransport = null;

  videoProducer = null;
  audioProducer = null;

  _peers = [];
  peerUpdateCallback = null;
  activePeersCallback = null;
  tracksCallbacks = {};

  havePeers = false;

  constructor(uid, room, socket, setActivePeers) {
    this.device = new Device();
    this.queue = new Queue();

    this.uid = uid;
    this.room = room;

    this.signaling = new signaling(socket);
    this.activePeersCallback = setActivePeers;
  }

  async setPeerUpdateFunction(cb) {
    this.peerUpdateCallback = cb;
    this.updatePeers();
  }

  setTracksCallback(peerId, cb) {
    this.tracksCallbacks[peerId] = cb;
    this.updateTracks(peerId);
  }

  async updatePeers() {
    this.peerUpdateCallback && this.peerUpdateCallback(this._peers);
    if (!this.havePeers && this._peers.length) {
      this.havePeers = true;
      this.activePeersCallback(true);
    } else if (this.havePeers && !this._peers.length) {
      this.havePeers = false;
      this.activePeersCallback(false);
    }
  }

  async updateTracks(peerId) {
    const peer = byId(this._peers, peerId);
    if (!peer) return;
    const tracks = peer.consumers.map((c) => c.track);
    const cb = this.tracksCallbacks[peerId];
    cb && cb(tracks);
  }

  async loadDevice(routerRtpCapabilities) {
    if (!this.device.loaded) {
      // Remove video orientation header for Firefox compatibility
      routerRtpCapabilities.headerExtensions =
        routerRtpCapabilities.headerExtensions.filter(
          (ext) => ext.uri !== 'urn:3gpp:video-orientation'
        );
      await this.device.load({ routerRtpCapabilities });
    }
  }

  get rtpCapabilities() {
    return this.device.rtpCapabilities;
  }

  connectionCallback(id) {
    return async ({ dtlsParameters }, callback, errback) => {
      try {
        await this.signaling.connectTransport({
          room: this.room,
          dtlsParameters,
          transportId: id,
        });
        callback();
      } catch (error) {
        errback(error);
        console.warn('ERROR CONNECTING TRANSPORT', error);
      }
    };
  }

  async createTransports(canProduce = true) {
    const { rtpCapabilities, recvTransport, sendTransport } =
      await this.signaling.createTransports({
        uid: this.uid,
        room: this.room,
        canProduce,
      });
    await this.loadDevice(rtpCapabilities);
    if (recvTransport) this.createRecvTransport(recvTransport);
    if (sendTransport) this.createSendTransport(sendTransport);
  }

  closeTransports() {
    this.queue.clear();
    this.clearPeers();
    if (this.sendTransport) {
      this.sendTransport.close();
      this.sendTransport = null;
    }
    if (this.recvTransport) {
      this.recvTransport.close();
      this.recvTransport = null;
    }
    this.videoProducer = null;
    this.audioProducer = null;
  }

  createSendTransport(createTransportDto) {
    this.sendTransport = this.device.createSendTransport({
      ...createTransportDto,
      iceServers,
    });
    this.sendTransport.on(
      'connect',
      this.connectionCallback(this.sendTransport.id)
    );
    this.sendTransport.on(
      'produce',
      async ({ kind, rtpParameters }, callback, errback) => {
        try {
          const { id } = await this.signaling.produce({
            room: this.room,
            producerParameters: {
              transportId: this.sendTransport.id,
              kind,
              rtpParameters,
            },
          });
          callback({ id });
        } catch (error) {
          errback(error);
          console.warn('ERROR CREATING TRANSPORT', error);
        }
      }
    );
  }

  createRecvTransport(createTransportDto) {
    this.recvTransport = this.device.createRecvTransport({
      ...createTransportDto,
      iceServers,
    });
    this.recvTransport.on(
      'connect',
      this.connectionCallback(this.recvTransport.id)
    );
  }

  async createProducer(track) {
    const task = async () => {
      if (!this.sendTransport)
        throw new Error('Cannot create producer: No transport');
      if (!track) throw new Error('Cannot create producer: No track provided');

      if (track.kind === 'audio') {
        this.audioProducer = await this.sendTransport.produce({
          track,
          stopTracks: false,
        });
      } else {
        const encodings =
          track.getSettings().width >= 1280 ? encodingsHQ : encodingsMQ;

        this.videoProducer = await this.sendTransport.produce({
          encodings,
          track,
          stopTracks: false,
        });
      }
    };

    return await new Promise((resolve) => this.queue.add(task, resolve));
  }

  async closeProducer(type) {
    const task = async () => {
      let producerId;
      if (type === 'MIC') {
        if (!this.audioProducer) return;
        producerId = this.audioProducer.id;
        this.audioProducer.close();
        this.audioProducer = null;
      } else {
        if (!this.videoProducer) return;
        producerId = this.videoProducer.id;
        this.videoProducer.close();
        this.videoProducer = null;
      }
      if (!producerId) return;

      this.signaling.closeProducer({ producerId });
    };

    return await new Promise((resolve) => this.queue.add(task, resolve));
  }

  async createConsumer(createConsumerDto) {
    if (!this.recvTransport)
      throw new Error('Cannot create consumer: No transport');

    return await this.recvTransport.consume(createConsumerDto);
  }

  onPeerJoin({ uid }) {
    if (uid === this.uid) return;

    const peer = { id: uid, consumers: [] };
    this._peers.push(peer);
    this.updatePeers();
    return peer;
  }

  onPeerLeave({ uid }) {
    const peer = byId(this._peers, uid);
    if (peer) {
      peer.consumers.forEach((consumer) => consumer.close());
      this._peers.splice(this._peers.indexOf(peer), 1);
      this.updatePeers();
      delete this.tracksCallbacks[peer.id];
    }
  }

  clearPeers() {
    this._peers.forEach(this.onPeerLeave);
  }

  async createRoomConsumers() {
    const producers = await this.signaling.createRoomConsumers({
      uid: this.uid,
      transportId: this.recvTransport.id,
      rtpCapabilities: this.rtpCapabilities,
    });
    producers.forEach((producer) => this.onNewProducer(producer));
  }

  async onNewProducer({ uid, consumer }) {
    if (uid === this.uid) return;

    const task = async () => {
      const peer = byId(this._peers, uid) || this.onPeerJoin({ uid });

      const localConsumer = await this.createConsumer(consumer);
      peer.consumers.push(localConsumer);
      this.signaling.resumeConsumer({
        uid: this.uid,
        consumerId: localConsumer.id,
      });
      this.updateTracks(peer.id);
    };

    return await new Promise((resolve) => this.queue.add(task, resolve));
  }

  async onClosedProducer({ uid, producerId }) {
    if (uid === this.uid) return;

    const task = () => {
      const peer = byId(this._peers, uid);
      if (!peer) return console.warn('No Peer in handleClosedProducer');
      const consumer = peer.consumers.find(
        (target) => target.producerId === producerId
      );
      if (!consumer) return;
      consumer.close();
      peer.consumers.splice(peer.consumers.indexOf(consumer), 1);
      this.updateTracks(peer.id);
    };

    return await new Promise((resolve) => this.queue.add(task, resolve));
  }

  async replaceVideoTrack(track) {
    if (!track) return console.warn('Replace Track unsuccessful');
    if (!this.videoProducer) this.createProducer(track);
    else await this.videoProducer.replaceTrack({ track });
  }

  async startRecord() {
    return this.signaling.startRecord();
  }

  async stopRecord() {
    return this.signaling.stopRecord();
  }
}
