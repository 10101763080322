import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faComment,
  faCopy,
  faEllipsisV,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFilePdf,
  faFileUpload,
  faFileVideo,
  faFolder,
  faFolderOpen,
  faMicrophone,
  faMicrophoneSlash,
  faMusic,
  faPaperPlane,
  faPhone,
  faPhoneAlt,
  faRecordVinyl,
  faShare,
  faShareSquare,
  faSignOutAlt,
  faTimes,
  faUsers,
  faVideo,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faComment,
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
  faRecordVinyl,
  faShare,
  faTimes,
  faUsers,
  faVideo,
  faVideoSlash,
  faPhoneAlt,
  faShareSquare,
  faPaperPlane,
  faEye,
  faEyeSlash,
  faFileUpload,
  faFolder,
  faFolderOpen,
  faFile,
  faSignOutAlt,
  faExternalLinkAlt,
  faFileAlt,
  faEllipsisV,
  faFilePdf,
  faFileAudio,
  faFileVideo,
  faFileArchive,
  faMusic,
  faCopy
);
