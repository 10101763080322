import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loader({ label }) {
  return (
    <div className="container d-flex justify-content-center h-100 bg-light">
      <div className="flex-column justify-content-center align-self-center">
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          {label && <p>{label}</p>}
        </div>
      </div>
    </div>
  );
}

export default Loader;
