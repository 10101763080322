function socketPromise(socket) {
  return function (event, payload) {
    return new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error(`Timed Out Request: ${event}`)), 10000);
      socket.emit(event, payload, resolve);
    });
  };
}

export default class MediaSoupSignaling {
  constructor(socket) {
    this.socket = socket;
    this.socket.request = socketPromise(socket);
  }

  createTransports = async (payload) => {
    return this.socket.request('CREATE_TRANSPORTS', payload);
  };

  createRoomConsumers = async (payload) => {
    return this.socket.request('CREATE_ROOM_CONSUMERS', payload);
  };

  connectTransport = async (payload) => {
    return this.socket.request('CONNECT_TRANSPORT', payload);
  };

  produce = async (payload) => {
    return this.socket.request('PRODUCE', payload);
  };

  closeProducer = (payload) => {
    this.socket.emit('CLOSE_PRODUCER', payload);
  };

  resumeConsumer = (payload) => {
    this.socket.emit('RESUME_CONSUMER', payload);
  };

  startRecord = async () => {
    return this.socket.request('START_RECORD');
  };

  stopRecord = async () => {
    return this.socket.request('STOP_RECORD');
  };
}
