import React, { useEffect, useRef, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { MixAudio } from '../dummy/audio';
import { Drawer } from '../dummy/drawer';
import { Recorder } from '../dummy/recorder';
import { byId } from '../helpers/byId';
import DummyChannelProvider, {
  useDummyChannelContext,
} from '../hooks/dummy/useDummyChannel';
import useQuery from '../hooks/useQuery';

function DummyScreen() {
  const dummyContainer = useRef(null);
  const dummyCanvas = useRef(null);

  const [mixAudio, setMixAudio] = useState(null);
  const stream = useRef(null);

  const prevVideoTracks = useRef([]);
  const prevAudioTracks = useRef([]);

  const mediaRecorders = useRef({});

  const { socket, mediasoup } = useDummyChannelContext();
  const [peers, setPeers] = useState([]);

  const updatePeers = (currentPeers) => {
    let newPeers = [];
    setPeers((oldPeers) => {
      const existingPeers = oldPeers.filter((p) => byId(currentPeers, p.id));
      newPeers = currentPeers.filter((p) => !byId(oldPeers, p.id));
      newPeers.forEach((peer) => {
        peer.updateTracks = () => setPeers((peers) => [...peers]);
      });
      return [...existingPeers, ...newPeers];
    });
    newPeers.forEach((peer) =>
      mediasoup.manager.setTracksCallback(peer.id, peer.updateTracks)
    );
  };

  useEffect(() => {
    const setPeerUpdateFunction = () => {
      mediasoup.manager.setPeerUpdateFunction(updatePeers);
    };
    if (mediasoup.manager) setPeerUpdateFunction();
  }, [mediasoup.manager]);

  useEffect(() => {
    const canvas = dummyCanvas.current;
    const container = dummyContainer.current;
    const d = new Drawer(canvas, container);
    const a = new MixAudio();

    const videoStream = d.getStream();
    const videoTrack = videoStream.getVideoTracks()[0];
    const audioStream = a.getStream();
    const audioTrack = audioStream.getAudioTracks()[0];

    stream.current = new MediaStream([videoTrack, audioTrack]);
    setMixAudio(a);
  }, []);

  const updateVideos = (prevVideoTracks, videoTracks) => {
    if (!dummyContainer.current) return;

    videoTracks.forEach((track) => {
      if (byId(prevVideoTracks, track.id)) return;
      const videoElement = document.createElement('video');
      dummyContainer.current.appendChild(videoElement);
      videoElement.id = track.id;
      videoElement.srcObject = new MediaStream([track]);
      videoElement.muted = true;
      videoElement.width = '80';
      videoElement.height = '45';
      videoElement.play();
    });

    prevVideoTracks.forEach((track) => {
      if (byId(videoTracks, track.id)) return;
      const videoElement = document.getElementById(track.id);
      videoElement && dummyContainer.current.removeChild(videoElement);
    });
  };

  const updateAudios = (prevAudioTracks, audioTracks) => {
    if (!mixAudio) return;
    audioTracks.forEach((at) => {
      if (!byId(prevAudioTracks, at.id)) mixAudio.addAudio(at);
    });
    prevAudioTracks.forEach((at) => {
      if (!byId(audioTracks, at.id)) mixAudio.removeAudio(at);
    });
  };

  useEffect(() => {
    const videoTracks = [];
    const audioTracks = [];
    peers.forEach((peer) => {
      peer.consumers.forEach((consumer) => {
        if (consumer.kind === 'audio') audioTracks.push(consumer.track);
        else videoTracks.push(consumer.track);
      });
    });
    updateVideos(prevVideoTracks.current, videoTracks);
    prevVideoTracks.current = videoTracks;
    updateAudios(prevAudioTracks.current, audioTracks);
    prevAudioTracks.current = audioTracks;
  }, [peers]);

  const startRecording = (payload) => {
    if (!stream.current) return;
    const { recordId, fileName } = payload;

    const recorder = new Recorder(stream.current, fileName);
    recorder.start();
    mediaRecorders.current[recordId] = recorder;
  };

  const stopRecording = (payload) => {
    const { recordId } = payload;
    const recorder = mediaRecorders.current[recordId];
    recorder && recorder.stop();
    delete mediaRecorders.current[recordId];
  };

  useEffect(() => {
    if (socket) {
      socket.on('DUMMY_START_RECORDING', startRecording);
      socket.on('DUMMY_STOP_RECORDING', stopRecording);
    }

    return () => {
      if (socket) {
        socket.off('DUMMY_START_RECORDING', startRecording);
        socket.off('DUMMY_STOP_RECORDING', stopRecording);
      }
    };
  }, [socket]);

  return (
    <>
      <div ref={dummyContainer} />
      <canvas ref={dummyCanvas} width="1280" height="720" />
    </>
  );
}

function DummyPage() {
  const query = useQuery();
  const userId = query.get('uid');
  const channelName = query.get('room');
  const channelProps = { channelName, userId };

  if (!userId || !channelName) {
    return <Redirect to="/404" />;
  }

  return (
    <DummyChannelProvider {...channelProps}>
      <DummyScreen />
    </DummyChannelProvider>
  );
}

export default withRouter(DummyPage);
