import React from 'react';

function NotFound(props) {
  let message = 'The page you are looking for does not exist.';
  if (props.location.state) {
    message = props.location.state.message;
  }

  return (
    <div className="container d-flex justify-content-center h-100">
      <div className="flex-column justify-content-center align-self-center">
        <h2 className="font-weight-light">404 Not found</h2>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default NotFound;
