import './index.css';

import React from 'react';
import { Link } from 'react-router-dom';

import { useRouteContext } from '../../../hooks/useRouter';

function ScheduleItem({ schedule }) {
  const { prefix } = useRouteContext();

  return (
    <Link to={`${prefix}/schedules/${schedule.id}`}>
      <li className="list-group-item d-flex justify-content-between align-items-center">
        <div className="schedule-list">
          <div>{schedule.id}</div>
          <div className="badge badge-primary badge-pill">
            {schedule.number_of_interviews}
          </div>
        </div>
      </li>
    </Link>
  );
}

export default function ScheduleList({ schedules }) {
  return (
    <ul className="list-group">
      {schedules.length > 0 &&
        schedules.map((schedule) => {
          return <ScheduleItem key={schedule.id} schedule={schedule} />;
        })}
    </ul>
  );
}
