const env = process.env;

module.exports = {
  app: {
    name: env.REACT_APP_NAME || '',
    description: env.REACT_APP_DESCRIPTION || '',
    environment: env.REACT_APP_ENVIRONMENT || 'development',
    language: env.REACT_APP_LANGUAGE_DEFAULT || 'en',
    url: env.REACT_APP_URL || 'http://localhost',
  },
  client: {
    1: { title: 'Interview Adjuster', favicon: '/favicon_ia.ico' },
    2: { title: 'magic VISION', favicon: '/favicon_mv.ico' },
  },
  web: {
    admin: {
      prefix: env.REACT_APP_ADMIN_PREFIX || '/admin',
    },
    user: {
      prefix: env.REACT_APP_USER_PREFIX || '',
    },
  },

  api: {
    url: env.REACT_APP_API_URL || '',
    key: env.REACT_APP_API_TOKEN || '',
  },

  socket: {
    uri: env.REACT_APP_SOCKET_URI || '',
  },

  validation: {
    date: false,
  },

  axios: {
    // Timeout in seconds
    timeout: 180,
  },

  media: {
    videoOptions: {
      width: { ideal: 1920, max: 1920 },
      height: { ideal: 1080 },
      frameRate: { max: 30 },
    },

    microphoneOptions: {
      audio: true,
    },

    screenCaptureOptions: {
      width: { max: 1920 },
      height: { max: 1080 },
      frameRate: { max: 30 },
    },

    // Simulcast encodings
    encodingsHQ: [
      { maxBitrate: 300000, scaleResolutionDownBy: 2 },
      { maxBitrate: 1000000 },
      { maxBitrate: 2000000 },
    ],
    encodingsMQ: [
      { maxBitrate: 300000, scaleResolutionDownBy: 2 },
      { maxBitrate: 2000000 },
    ],

    turnIP: env.REACT_APP_TURN_IP || '',
    stunIP: env.REACT_APP_STUN_IP || '',
    turnUserName: env.REACT_APP_TURN_USERNAME || '',
    turnPassword: env.REACT_APP_TURN_PASSWORD || '',
    isRecordingDisabled:
      env.REACT_APP_RECORDING_DISABLED?.toLowerCase() === 'true',
  },
};
