import http from './httpService';

export async function uploadFile(payload = {}) {
  const fd = new FormData();
  fd.append('file', payload);
  const { data } = await http.post('/upload', fd);
  return data;
}

export async function createMedia(payload = {}) {
  const { data } = await http.post('/interviews-media', payload);
  return data;
}

export async function getMedia(id, payload = {}) {
  return await http.get(`/interviews/${id}`, payload);
}

export async function deleteMedia(id) {
  return await http.delete(`/interviews-media/${id}`);
}

export default {
  uploadFile,
  getMedia,
  deleteMedia,
};
