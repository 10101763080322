import fixWebmDuration from 'webm-duration-fix';

const MIME_TYPE = 'video/webm;codecs=vp9';

export class Recorder {
  mediaRecorder = null;
  chunks = [];

  constructor(stream, fileName) {
    this.mediaRecorder = new MediaRecorder(stream, {
      mimeType: MIME_TYPE,
    });
    this.mediaRecorder.ondataavailable = (e) => {
      this.chunks.push(e.data);
    };
    this.mediaRecorder.onstop = async () => {
      const blob = await fixWebmDuration(
        new Blob(this.chunks, { type: MIME_TYPE })
      );
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style = 'display: none';
      a.href = url;
      a.download = fileName;
      a.click();
      this.chunks = [];
      window.URL.revokeObjectURL(url);
    };
  }

  start() {
    if (this.mediaRecorder) this.mediaRecorder.start(100);
  }

  stop() {
    if (this.mediaRecorder) this.mediaRecorder.stop();
  }
}
