import './index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { useAuthContext } from '../../hooks/useAuth';
import auth from '../../services/authService';
import config from '../../services/configService';

const redirectTo = config.get('web.admin.prefix');
function Login(props) {
  const { setToken } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const handleError = (status, data) => {
    const errorMappers = {
      400: () => setError('メールアドレスまたはパスワードは正しくありません。'),
      422: () => setEmailError(data.message.email),
      404: () => setError('メールアドレスまたはパスワードは正しくありません。'),
    };
    errorMappers[status] && errorMappers[status]();
  };

  const handleLogin = (evt) => {
    evt.preventDefault();
    setEmailError(!email ? 'メールアドレスは無効です。' : '');
    setPasswordError(!password ? 'パスワードは無効です。' : '');
    if (!email || !password) return;
    doLogin();
  };

  const doLogin = async () => {
    try {
      await auth.login(email, password);
      setToken(auth.getAccessToken());
      props.history.push(redirectTo);
    } catch (ex) {
      if (!ex.response) return;
      handleError(ex.response.status, ex.response.data);
    }
  };

  const onChangeEmail = ({ currentTarget: input }) => {
    setEmail(input.value);
  };

  const onChangePassword = ({ currentTarget: input }) => {
    setPassword(input.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div className="formLogin">
      <Card
        bg="Light"
        style={{ width: '24rem', margin: '0 auto' }}
        className="mb-2">
        <Card.Body>
          <Form onSubmit={handleLogin}>
            <Col className="text-center">
              <Form.Label>ログイン</Form.Label>
            </Col>
            {error && (
              <Form.Text className="text-danger mb-3">{error}</Form.Text>
            )}
            <Form.Group controlId="formEmail">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                name="email"
                value={email}
                onChange={onChangeEmail}
                placeholder="メールアドレスを入力する"
              />
              {emailError && (
                <Form.Text className="text-danger">
                  {'メールアドレスは無効です。'}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>パスワード</Form.Label>
              <Form.Control
                name="password"
                value={password}
                onChange={onChangePassword}
                type={passwordShown ? 'text' : 'password'}
                placeholder="パスワードを入力する"
                id="password"
              />
              <div onClick={togglePasswordVisibility}>
                <FontAwesomeIcon
                  icon="eye"
                  className="float-right icon-eye"
                  style={{
                    display: passwordShown ? 'block' : 'none',
                  }}
                />
                <FontAwesomeIcon
                  icon="eye-slash"
                  className="float-right icon-eye"
                  style={{
                    display: !passwordShown ? 'block' : 'none',
                  }}
                />
              </div>

              {passwordError && (
                <Form.Text className="text-danger">
                  {'パスワードは無効です。'}
                </Form.Text>
              )}
            </Form.Group>
            <Col className="text-center">
              <Button variant="primary" type="submit">
                ログイン
              </Button>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default withRouter(Login);
