const storageKey = 'chatId';

export const useUniqueId = (channel, role) => {
  const generateNewId = () => {
    return Math.floor(10000 + Math.random() * 90000);
  };

  const setNewId = () => {
    sessionStorage.setItem(
      storageKey,
      JSON.stringify({ channel, [role]: role + generateNewId() })
    );
  };

  const getId = () => {
    const id = JSON.parse(sessionStorage.getItem(storageKey));
    return id[role];
  };

  if (!sessionStorage.getItem(storageKey)) {
    setNewId();
  } else {
    // new id for new channel
    const id = JSON.parse(sessionStorage.getItem(storageKey));
    if (id.channel !== channel) {
      setNewId();
    }
  }

  return {
    generateNewId,
    getId,
    setNewId,
  };
};

export default useUniqueId;
