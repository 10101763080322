export const calcMosiacLayout = (containerWidth, containerHeight, numItems) => {
  let cols = 1;
  while (numItems > cols * cols) cols++;

  const itemWidth = containerWidth / cols;
  const itemHeight = containerHeight / cols;

  const offsetTop = numItems > cols * (cols - 1) ? 0 : itemHeight / 2;
  const offsetLeft = ((cols - (numItems % cols)) / 2) * itemWidth;

  const itemPositions = [];
  for (let i = 0; i < numItems; i++) {
    const ix = i % cols;
    const iy = Math.floor(i / cols);
    const y = iy * itemHeight + offsetTop;
    const offset = (iy + 1) * cols <= numItems ? 0 : offsetLeft;
    const x = (ix % cols) * itemWidth + offset;
    itemPositions.push({ x, y });
  }

  return {
    itemWidth,
    itemHeight,
    itemPositions,
  };
};
